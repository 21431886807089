import $ from 'jquery'

const $doc = $(document);
const $mobileNav = $('.js-mobile-nav');
const $hamburger = $('.js-hamburger');
const $arrow = $('.hamburger--svg');
const $mobileNavSub = $('.js-mobile-nav .menu-item-has-children');
$mobileNavSub.attr('aria-expanded', 'false');
$mobileNavSub.children('a').wrap("<div class=link-wrap></div>");
$mobileNavSub.children('.link-wrap').append( '<span><svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.907 2.28656C17.9596 2.22921 17.9596 2.14116 17.907 2.08381L16.1068 0.120565C16.0473 0.0557349 15.9451 0.0557351 15.8857 0.120565L9.11055 7.50907C9.05111 7.57389 8.94889 7.57389 8.88944 7.50906L2.11433 0.120565C2.05488 0.0557346 1.95267 0.0557351 1.89322 0.120565L0.0929613 2.08381C0.040369 2.14116 0.0403691 2.22921 0.0929613 2.28656L8.88944 11.8794C8.94889 11.9443 9.05111 11.9443 9.11056 11.8794L17.907 2.28656Z" fill="#221F20"/></svg></span>');

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  $arrow.toggleClass('is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}

function mobileDropdowns() {
  const submenu = $(this).children('.sub-menu');
  const link = $(this).find('.link-wrap a');
  const span = $(this).find('span');
  submenu.slideToggle(500);
  link.toggleClass('is-active');
  span.toggleClass('is-active');
  $('.sub-menu').not(submenu).slideUp(500);
  $(this).attr('aria-expanded',
    $(this).attr('aria-expanded')=='false' ? 'true' : 'false'
  );
  $mobileNavSub.not($(this)).attr('aria-expanded', 'false');
  $('.link-wrap a').not(link).removeClass('is-active');
  $('span').not(span).removeClass('is-active');
}

export default function initMobileNav() {
  $doc.on('click', '.js-hamburger', showMobileNav );
  $doc.on('keydown', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
      $arrow.toggleClass('is-active')
    }
  });
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( $mobileNav.hasClass('is-active') ) {
        $hamburger.attr('aria-expanded', 'false')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
        $arrow.toggleClass('is-active')
      }
    }
  });
  $doc.on('click', '.js-mobile-nav .menu-item-has-children', mobileDropdowns );
}
