import $ from 'jquery'

const banner = $('.js-bar');
const main = $('main');
const header = $('.js-header');
const mainWrap = main.css('paddingTop');

function initQuoteSlickSlider(){
  if ($('.js-quote').length) {
    $('.js-quote').slick({
      //speed: 400,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      infinite: true,
      arrows: true,
      buttons: false,
    });
  }
}

export default function initSlick(){
  $(document).ready( initQuoteSlickSlider )
}
