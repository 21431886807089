import $ from 'jquery';
const $doc = $(document);
const categories = $('.js-categories');
const arrow = $('.js-mobile-arrow');

function toggleCat() {
  if ($(window).width() < 960) {
    $(this).next('ul').slideToggle();
    arrow.toggleClass('is-active');
  }
}

function toggleName(event) {
  if ($(window).width() < 960) {
    const el = $(event.currentTarget);
    let link = el.attr('href');
    let text = el.text();
    categories.slideUp();
    $('.mobile-categories a').attr('href',link).text(text).addClass('active');
    $('.js-categories > li').show();
    el.parent().hide();
  }
}

function toggleMobile() {
  if ($(window).width() > 960) {
    categories.show();
    $('.js-categories li a').filter('.active').show();
    $('.resources-nav').show().css('display', 'flex');
  } else {
    $('.js-categories li a').filter('.active').hide();
    $('.resources-nav').hide();
    arrow.removeClass('is-active');
  }
}

export default function initMobileCategories() {
  if ($(window).width() < 960) {
    $('.js-categories li a').filter('.active').hide();
  }
  $(window).on('resize', toggleMobile);
  $doc.on('click', '.js-mobile-categories', toggleCat );
  $doc.on('click', '.js-categories > li > a', toggleName );
}
