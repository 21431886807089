import $ from 'jquery';

const locations = $('.map__text--location');
const initialLocation = $('#1.map__text--location');
const initialCircle = $('#1.map-location');

function init() {
  initialLocation.fadeIn(100);
  initialCircle.addClass('is-hovered');
  checkWindow();

  function checkWindow() {
    $('.map-location').off('mouseover').off('click');
    if ($(window).width() > 960) {
      $('.map__text--location-address').off('click');
      $('.map-location').on('mouseover', function(e) {
        e.preventDefault();
        const id = $(this).attr('id');
        const location = $('#'+id+'.map__text--location');
        const link = location.attr('data-link');
        locations.fadeOut(100);
        $('.map__text--location:not(#'+id+')').hide();
        $('.map-location:not(#'+id+')').removeClass('is-hovered');
        initialCircle.removeClass('is-hovered');
        location.fadeIn(200);
        $(this).addClass('is-hovered');
        $(this).on('click', function(e) {
          console.log('click');
          window.open(link, '_blank');
          e.stopImmediatePropagation();
        });
      });
    } else {
      $('.map-location').on('click', function(e) {
        e.preventDefault();
        const id = $(this).attr('id');
        const location = $('#'+id+'.map__text--location');
        const link = location.attr('data-link');
        locations.fadeOut(100);
        $('.map__text--location:not(#'+id+')').hide();
        $('.map-location:not(#'+id+')').removeClass('is-hovered');
        initialCircle.removeClass('is-hovered');
        location.fadeIn(200);
        $(this).addClass('is-hovered');
        $('.map__text--location-address').on('click', function(e) {
          window.open(link, '_blank');
          e.stopImmediatePropagation();
        });
      });
    }
  }

  $(window).on('resize', function(){
    checkWindow();
  });
}

export default function initMap() {
  $(document).ready( init );
  //$(document).on('mouseenter', '.map-location', showLocation );
  //$(document).on('click', '.map-location', showLocationMobile );
}
