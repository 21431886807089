import $ from 'jquery'

const $doc = $(document);
const filterForm = $('.team__filters--search-form');
const loader = $('.team__loader');
const teamList = $('.js-team-list');
const leadership = $('.team__leadership');

const filterTeam =  {
  parseResponse: (posts) => {
    for (let post of posts) {
      teamList.append(`
        <div class="grid__column u-size-1of2--sm u-size-1of3--md u-size-1of5--lg team-member__wrapper">
          ${post.bio ? '<a class="team-member" href="'+post.permalink+'">' : '<div>'}
            <div class="team-member__bg">
              ${post.image ? '<div class="team-member__image lazyload" data-bg="'+post.image+'"></div>' : ''}
            </div>
            <div class="team-member__title">
              <h3>${post.title}</h3>
              <h4>${post.position}</h4>
            </div>
          ${post.bio ? '</a>' : '</div>'}
        </div>
      `)
    }
  },
  filterTeam: (data) => {
    teamList.empty();
    loader.addClass('loading')
    return $.ajax({
      url : ajax_object.ajax_url,
      data : data,
      success : function(response) {
        filterTeam.parseResponse(response);
        loader.removeClass('loading');
      }
  });
  }
}

function openLeadership(e) {
  e.preventDefault();
  leadership.slideDown();
}

function closeLeadership(e) {
  e.preventDefault();
  leadership.slideUp();
}

export default function initTeamFilter() {
  filterForm.submit(function(e){
    leadership.slideUp();
    e.preventDefault();
    const searchTerm = filterForm.find("#search").val();
    const data = {
      action : "get_team_members",
      search : searchTerm
    }
    filterTeam.filterTeam(data);
  });
  $doc.on('click', '.js-team-leadership', openLeadership );
  $doc.on('click', '.js-team-all', closeLeadership );
}
